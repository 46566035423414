import { userThunks } from "entities/User/model/services/thunks";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { userApi } from "../api/userApi";
import { userActions } from "entities/User/model/slice";

export const getMe = createAsyncThunk("user/getMe", async (_, { dispatch }) => {
  dispatch(userActions.isLoading(true));
  dispatch(userActions.isError(null));
  try {
    const user = await userApi.getMe();
    return { user };
  } catch (e) {
    dispatch(userActions.isError(e));
  } finally {
    dispatch(userActions.isLoading(false));
  }
});
