import { useAppSelector } from "app/providers/StoreProvider/config/store";
import { userSelector } from "entities/User/model/selectors";
import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Preloader from "shared/ui/Preloader/Preloader";

export const AdminLayout = ({ children }: { children: React.ReactNode }) => {
  const [isLoading, setIsLoading] = React.useState(true);
  const navigate = useNavigate();
  const userData = useAppSelector(userSelector.currentUser);
  useEffect(() => {
    if (userData && !userData.is_superuser) {
      navigate(-1);
    } else {
      setIsLoading(false);
    }
  }, [userData]);

  if (isLoading) {
    return <Preloader isContentPreloader />;
  }

  // <Outlet />;
  return <>{children}</>;
};
