import { createAsyncThunk } from "@reduxjs/toolkit";

import { usersActions } from "../../slice";
import { AdminApi } from "entities/Admin";

export const getUsersList = createAsyncThunk(
  "users/getUsersList",
  async (
    { offset, limit = 50 }: { offset: number; limit?: number },
    { dispatch }
  ) => {
    dispatch(usersActions.isLoading(true));
    dispatch(usersActions.isError(null));
    try {
      const data = await AdminApi.getUsersList(offset, limit);
      return data;
    } catch (e) {
      dispatch(usersActions.isError(e));
    } finally {
      dispatch(usersActions.isLoading(false));
    }
  }
);
