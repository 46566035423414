import { AuthLayout } from "layouts/AuthLayout";
import { PageLayout } from "layouts/PageLayout";
import { Login } from "pages/auth/Login";
import { NewPassword } from "pages/auth/NewPassword";
import { ResetPassword } from "pages/auth/ResetPassword";
import { SignUp } from "pages/auth/SignUp";
import { AudioTranscript } from "pages/content/AudioTranscript";
import { ChatBot } from "pages/content/ChatBot";
import { AllDocuments, Document } from "pages/content/Document";
import { NotFound } from "pages/content/NotFound";
import { Payment } from "pages/content/Payment";
import { Settings } from "pages/content/Settings";
import { Statistic } from "pages/content/Statistic";
import { GeneratingImages } from "pages/content/GeneratingImages";
import { TextEditor } from "pages/content/TextEditor";
import { TGAuntificator } from "pages/content/TGAuntificator";
import {
  AppRoutes,
  ROUTE_LAYOUTS,
  RoutePath,
} from "shared/config/routeConfig/routeConfig";
import { ChatBotLayout } from "layouts/ChatBotLayout";
import { VoiceTranscript } from "features/VoiceTranscript";
import { BackgroundReplacement } from "pages/content/BackgroundReplacement";
import FetchDataLayout from "layouts/FetchDataLayout/FetchDataLayout";
import { Support } from "../../../../pages/content/Support";
import { NewRequest } from "../../../../pages/content/Support/ui/NewRequest/NewRequest";
import { SupportDetail } from "../../../../pages/content/Support/ui/SupportDetail/SupportDetail";
import { Tariffs } from "pages/content/Tariffs";
import { RecoveryMessage } from "pages/auth/RecoveryMessage";
import { EmailConfirmation } from "pages/auth/EmailConfirmation";
import { AccountPasswordSetting } from "pages/auth/AccountPasswordSetting";
import ErrorMessage from "pages/auth/ErrorMessage/ErrorMessage";
import PasswordSuccessChanged from "pages/auth/PasswordSuccessChanged/PasswordSuccessChanged";
import GeneratingVideo from "pages/content/GeneratingVideo/ui/GeneratingVideo/GeneratingVideo";
import AuthCallbackPage from "../../../../pages/auth/AuthCallback/ui/AuthCallbackPage";
import { UserInfo, Users } from "pages/admin/Users";
import { AdminLayout } from "layouts/AdminLayout";

//Record<ROUTE_LAYOUTS, RouteProps[]>
export const CHILDREN_ROUTES = {
  [ROUTE_LAYOUTS.AUTH]: {
    layoutPath: "/",
    layout: <AuthLayout />,
    routes: [
      {
        path: RoutePath[AppRoutes.LOGIN],
        element: <Login />,
      },
      {
        path: RoutePath[AppRoutes.REGISTER],
        element: <SignUp />,
      },
      {
        path: RoutePath[AppRoutes.CREATE_NEW_PASSWORD],
        element: <NewPassword />,
      },
      {
        path: RoutePath[AppRoutes.RESET_PASSWORD],
        element: <ResetPassword />,
      },
      {
        path: RoutePath[AppRoutes.RECOVERY_MESSAGE],
        element: <RecoveryMessage />,
      },
      {
        path: RoutePath[AppRoutes.ERROR_MESSAGE],
        element: <ErrorMessage />,
      },
      {
        path: RoutePath[AppRoutes.EMAIL_CONFIRMATION],
        element: <EmailConfirmation />,
      },
      {
        path: RoutePath[AppRoutes.SET_ACCOUNT_PASSWORD],
        element: <AccountPasswordSetting />,
      },
      {
        path: RoutePath[AppRoutes.PASSWORD_SUCCESS_CHANGED],
        element: <PasswordSuccessChanged />,
      },
      {
        path: RoutePath[AppRoutes.YANDEX_AUTH_CALLBACK],
        element: <AuthCallbackPage />,
      },
    ],
  },
  [ROUTE_LAYOUTS.CONTENT]: {
    layoutPath: "/dashboard",
    layout: (
      <FetchDataLayout>
        <PageLayout />
      </FetchDataLayout>
    ),
    routes: [
      {
        path: RoutePath[AppRoutes.MAIN],
        element: <Statistic />,
      },
      {
        path: RoutePath[AppRoutes.SETTINGS],
        element: <Settings />,
      },
      {
        path: RoutePath[AppRoutes.PAYMENT],
        element: <Payment />,
      },

      {
        path: RoutePath[AppRoutes.AUDIO_TRANSCRIPT],
        element: <AudioTranscript />,
      },
      {
        path: RoutePath[AppRoutes.AI_IMAGES],
        element: <GeneratingImages />,
      },
      {
        path: RoutePath[AppRoutes.AI_VIDEOS],
        element: <GeneratingVideo />,
      },
      {
        path: RoutePath[AppRoutes.VOICE_OVER],
        element: <VoiceTranscript />,
      },
      {
        path: RoutePath[AppRoutes.BACKGROUND_REPLACEMENT],
        element: <BackgroundReplacement />,
      },
      {
        path: RoutePath[AppRoutes.SUPPORT],
        element: <Support />,
      },
      {
        path: `${RoutePath[AppRoutes.SUPPORT]}/:id`,
        element: <SupportDetail />,
      },
      {
        path: RoutePath[AppRoutes.SUPPORT_NEW_REQUEST],
        element: <NewRequest />,
      },
    ],
  },
  [ROUTE_LAYOUTS.ADMIN]: {
    layoutPath: "/dashboard/admin",
    layout: (
      <FetchDataLayout>
        <AdminLayout>
          <PageLayout />
        </AdminLayout>
      </FetchDataLayout>
    ),
    routes: [
      {
        path: RoutePath[AppRoutes.USERS],
        element: <Users />,
      },
      {
        path: RoutePath[AppRoutes.EDIT_USER],
        element: <UserInfo />,
      },
    ],
  },

  [ROUTE_LAYOUTS.DOCUMENTS]: {
    layoutPath: RoutePath[AppRoutes.TEXT_EDITOR],
    layout: (
      <FetchDataLayout>
        <TextEditor />
      </FetchDataLayout>
    ),
    routes: [
      {
        path: RoutePath[AppRoutes.TEXT_EDITOR],
        element: <AllDocuments />,
      },
      {
        path: RoutePath[AppRoutes.TEXT_EDITOR_DOCUMENT],
        element: <Document />,
      },
    ],
  },
  [ROUTE_LAYOUTS.CHAT_BOT]: {
    layoutPath: RoutePath[AppRoutes.CHAT_BOT],
    layout: (
      <FetchDataLayout>
        <ChatBotLayout />
      </FetchDataLayout>
    ),
    routes: [
      {
        path: RoutePath[AppRoutes.CHAT_BOT_CHAT],
        element: <ChatBot />,
      },
    ],
  },
  [ROUTE_LAYOUTS.STAND_ALONE]: {
    routes: [
      { path: RoutePath[AppRoutes.NOT_FOUND], element: <NotFound /> },
      {
        path: `${RoutePath[AppRoutes.TG_LINK]}/:id`, // Добавляем параметр :id
        element: <TGAuntificator />,
      },
      {
        path: RoutePath[AppRoutes.TARIFFS],
        element: (
          <FetchDataLayout>
            <Tariffs />
          </FetchDataLayout>
        ),
      },
    ],
  },
};
