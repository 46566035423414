import useHttp from "shared/api/services/api";
import { API_PREFIX } from "shared/api/constants/api";
import { TARIFFS_NAMES } from "shared/constants/common";

export class subscriptionsApi {
  static http = useHttp(API_PREFIX.SUBSCRIPTIONS);

  static async getMySubscription() {
    const { data } = await subscriptionsApi.http.get("/me");
    return data;
  }
  static async getMySubscriptionLimits() {
    const { data } = await subscriptionsApi.http.get("/me/limits");
    return data;
  }
  static async applyUserPlan(planId: string) {
    const { data } = await subscriptionsApi.http.post(
      `/me/apply_plan/${planId}`
    );
    return data;
  }
}
