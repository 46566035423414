import { createAsyncThunk } from "@reduxjs/toolkit";
import { TDocument, TDocumentFolder } from "../../slice/types";
import { textDocumentsActions } from "../../slice";
import foldersApi from "../api/folderApi";

export const updateFolder = createAsyncThunk(
  "textDocuments/updateFolder",
  async (
    { folder_id, folderName }: { folder_id: string; folderName: string },
    { rejectWithValue, dispatch }
  ) => {
    dispatch(textDocumentsActions.isLoading(true));
    try {
      dispatch(textDocumentsActions.isError(null));
      const res: TDocumentFolder = await foldersApi.updateFolder(folder_id, folderName);

      return { updatedFolder: res };
    } catch (e) {
      dispatch(textDocumentsActions.isError(e));
      rejectWithValue(e);
    } finally {
      dispatch(textDocumentsActions.isLoading(false));
    }
  }
);
