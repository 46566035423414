import { useAppDispatch } from "app/providers/StoreProvider/config/store";
import { userSelector } from "entities/User/model/selectors";
import { userThunks } from "entities/User/model/services/thunks";
import {
  subscriptionsSelector,
  subscriptionsThunks,
  userSubPlanActions,
} from "entities/UserSubPlan";
import LimitsModal from "features/LimitsModal/LimitsModal";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { LS_ACCESS_TOKEN } from "shared/api/constants/localStorage";
import { RoutePath } from "shared/config/routeConfig/routeConfig";
import Preloader from "shared/ui/Preloader/Preloader";

const FetchDataLayout = ({ children }: { children: React.ReactNode }) => {
  const [preloader, setPreloader] = useState(true);

  const userData = useSelector(userSelector.currentUser);
  const userPlan = useSelector(subscriptionsSelector.userSubPlan);
  const isLoading = useSelector(userSelector.isLoading);
  const isTokensLimitShown = useSelector(
    subscriptionsSelector.isTokensLimitShown
  );

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const setTokensLimitClosed = () =>
    dispatch(userSubPlanActions.setIsTokensLimitShown(false));

  useEffect(() => {
    if (!localStorage.getItem(LS_ACCESS_TOKEN)) {
      setPreloader(false);
      navigate(RoutePath.LOGIN);
    } else {
      if (!userData) {
        dispatch(userThunks.getMe());
      }
      if (!userPlan) {
        dispatch(subscriptionsThunks.getUserSubPlan());
      }
      setPreloader(false);
    }
  }, []);

  if (preloader || isLoading) {
    return <Preloader />;
  }
  return (
    <>
      {children}
      <LimitsModal
        open={isTokensLimitShown}
        onClose={setTokensLimitClosed}
        title={"Упс, закончились токены"}
        description={<></>}
        contentText={`Не позволь прервать работу с ИИ! Обнови свой тарифный план и продолжай использовать все возможности.`}
      />
    </>
  );
};

export default FetchDataLayout;
