import s from "./AuthWrapper.module.css";
import AuthHeader from "../AuthHeader/AuthHeader";
import AuthFooter from "../AuthFooter/AuthFooter";
import { useEffect, useState } from "react";
import WelcomeModal from "widgets/Header/WelcomeModal/WelcomeModal";

type TAuthWrapper = {
  title: string | JSX.Element;
  children?: JSX.Element;
  footerDescription?: string | JSX.Element;
  description?: string | JSX.Element;
  supportLink?: boolean;
  loginLink?: boolean;
};

export const AuthWrapper = ({
  title,
  children,
  footerDescription,
  description,
  supportLink,
  loginLink = false,
}: TAuthWrapper) => {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const wasVisited = localStorage.getItem("wasVisited");

    if (!wasVisited) {
      setTimeout(() => {
        setShowModal(true);
        localStorage.setItem("wasVisited", "true");
      }, 1000);
    }
  }, []);

  return (
    <>
      <div className={s.container}>
        <AuthHeader />
        <div className={s.contentBlock}>
          <div className={s.contentContainer}>
            {description ? (
              <div className="flex flex-col gap-4 items-center">
                <h1 className={s.title}>{title}</h1>
                <p className="text-gray-500 text-center">{description}</p>
              </div>
            ) : (
              <h1 className={s.title}>{title}</h1>
            )}
            <div className={s.contentChildren}>
              {children && children}
              {supportLink && (
                <p className="text-gray-500 text-sm text-center w-full !mt-[-16px]">
                  Что-то пошло не так?{" "}
                  <a href="https://monoai.ru/contact" className="link">
                    Свяжись с поддержкой.
                  </a>
                </p>
              )}
            </div>
          </div>
        </div>
        <AuthFooter description={footerDescription} loginLink={loginLink} />
      </div>
      <WelcomeModal open={showModal} onClose={() => setShowModal(false)} />
    </>
  );
};
