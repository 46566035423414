import React, { useState, useEffect, ChangeEvent } from "react";
import aiApi from "shared/api/services/aiApi";
import Bright from "shared/assets/icons/bright";
import BlackPlus from "shared/assets/icons/bplus";
import CoinPicXs from "shared/assets/icons/coin-pic-xs";
import ChevronUp from "shared/assets/icons/chevron-up";
import ChevronDown from "shared/assets/icons/chevron-down";
import s from "./GeneratingVideo.module.css";
import VideoStore from "../VideoStore/VideoStore";
import { PAGE_TITLES } from "shared/constants/common";
import PageTitle from "shared/ui/PageTitle/PageTitle";
import { useSelector } from "react-redux";
import {
  subscriptionsSelector,
  userSubPlanActions,
} from "entities/UserSubPlan";
import { useAppDispatch } from "app/providers/StoreProvider/config/store";

const GeneratingVideo = () => {
  const [isSettingsVisible, setIsSettingsVisible] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState<{ [key: string]: boolean }>({
    aspectRatio: false,
    duration: false,
  });

  const [aspectRatio, setAspectRatio] = useState("16:9");
  const [duration, setDuration] = useState(5);
  const [videoIdea, setVideoIdea] = useState("");
  const [videos, setVideos] = useState<Video[]>([]);
  const [isGenerating, setIsGenerating] = useState(false); // Состояние генерации видео
  const [isLoadingVideos, setIsLoadingVideos] = useState(true); // Состояние загрузки списка видео

  const aspectRatios = ["16:9", "9:16", "1:1"];
  const durations = [5, 10];

  const settingsConfig = {
    aspectRatio: { title: "Соотношение", options: aspectRatios },
    duration: { title: "Выбор времени", options: durations },
  };

  const userPlan = useSelector(subscriptionsSelector.userSubPlan);
  const dispatch = useAppDispatch();

  interface Video {
    id: string;
    url: string;
    prompt: string;
    model: string;
    size: string;
    duration: number;
    tokens_usage: number;
    created_at: string;
    user_id: string;
  }

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const response = await aiApi.getgenerateVideo("");
        console.log("Ответ от API:", response);

        if (Array.isArray(response)) {
          setVideos(response);
        } else {
          console.error("Ответ не является массивом:", response);
        }
      } catch (error) {
        console.error("Ошибка при загрузке видео:", error);
      } finally {
        setIsLoadingVideos(false); // Завершаем загрузку списка видео
      }
    };

    fetchVideos();
  }, []);

  const toggleSettingsVisibility = () => {
    setIsSettingsVisible((prev) => !prev);
  };

  const toggleDropdown = (dropdown: string) => {
    setDropdownOpen((prev) => ({
      ...prev,
      [dropdown]: !prev[dropdown],
    }));
  };

  const handleOptionSelect = (value: string | number, dropdown: string) => {
    if (dropdown === "aspectRatio") {
      setAspectRatio(value as string);
    } else if (dropdown === "duration") {
      setDuration(value as number);
    }

    setDropdownOpen((prev) => ({
      ...prev,
      [dropdown]: false,
    }));
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setVideoIdea(e.target.value);
  };

  const setTokensLimitShown = () =>
    dispatch(userSubPlanActions.setIsTokensLimitShown(true));

  const handleGenerateVideoClick = async () => {
    if (userPlan && userPlan.premium_tokens < 1) {
      setTokensLimitShown();
      return;
    }
    setIsGenerating(true);

    const requestData = {
      prompt: videoIdea,
      duration: duration.toString(),
      aspect_ratio: aspectRatio,
    };

    try {
      const res = await aiApi.generateVideo(
        "/video_kling_text_to_video",
        requestData
      );
      console.log("Результат генерации видео:", res);

      if (res && typeof res === "object" && res.id && res.url) {
        setVideos((prevVideos) => [res, ...prevVideos]);
      } else {
        console.error("Неверный формат ответа от API:", res);
      }
    } catch (error) {
      console.error("Ошибка при генерации видео:", error);
    } finally {
      setIsGenerating(false);
    }
  };

  return (
    <div>
      <PageTitle title="Генерация видео" />
      <div className={s.imageGen}>
        <div className={s.genSystem}>
          <button className={`${s.button} ${s.activeButton}`}>
            Kling v1.5 Pro
          </button>
        </div>
        <input
          type="text"
          className={s.illustrationinput}
          placeholder="Идея вашего видео..."
          value={videoIdea}
          onChange={handleInputChange}
        />
        <div className={s.buttoncontainer}>
          <button className={s.leftbutton} onClick={toggleSettingsVisibility}>
            Расширенные настройки <BlackPlus />
          </button>
          <div className={s.rightsection}>
            <p>
              <span style={{ color: "#98A2B3" }}>Спишется: </span> 100
            </p>
            <CoinPicXs />
            <button
              className={`${s.rightbutton} ${isGenerating ? s.disabledButton : ""}`}
              onClick={handleGenerateVideoClick}
              disabled={isGenerating}
            >
              {isGenerating ? "Генерируется..." : "Сгенерировать"} <Bright />
            </button>
          </div>
        </div>

        {isSettingsVisible && (
          <div className={s.settingsContainer}>
            {Object.entries(settingsConfig).map(([key, { title, options }]) => (
              <div className={s.setting} key={key}>
                <h4 className={s.settingTitle}>{title}</h4>
                <div className={s.dropdown}>
                  <button
                    className={s.dropdownButton}
                    onClick={() => toggleDropdown(key)}
                  >
                    {key === "aspectRatio" ? aspectRatio : duration}{" "}
                    {dropdownOpen[key] ? (
                      <ChevronUp width="16px" height="16px" />
                    ) : (
                      <ChevronDown width="16px" height="16px" />
                    )}
                  </button>
                  {dropdownOpen[key] && (
                    <div className={s.dropdownMenu}>
                      {options.map((option) => (
                        <div
                          key={option}
                          className={s.dropdownItem}
                          onClick={() => handleOptionSelect(option, key)}
                        >
                          {option} {key === "duration" && "сек"}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      <h3 className={s.result}>Результаты</h3>
      <div>
        <VideoStore
          videos={videos}
          loading_all={isLoadingVideos}
          loadingVideos={isGenerating}
        />
      </div>
    </div>
  );
};

export default GeneratingVideo;
