import useHttp from "shared/api/services/api";
import { API_PREFIX } from "shared/api/constants/api";
import { TARIFFS_NAMES } from "shared/constants/common";
import { TUser } from "entities/User";
export interface TPayments {
  total_payments: number;
  payments: {
    id: string;
    value: string;
    plan_id: string;
    plan_title: string;
    plan_tokens: number;
    plan_premium_tokens: number;
    plan_is_package: boolean;
    description?: string | null;
    status?: string | null;
    currency?: string;
    payment_method_title?: string | null;
  }[];
}
export class AdminApi {
  static http = useHttp(API_PREFIX.ADMIN);

  static async getUsersList(
    offset: number,
    limit = 50
  ): Promise<{ users: TUser[]; total_users: number }> {
    const { data } = await AdminApi.http.get(
      `/users/list?offset=${offset}&limit=${limit}`
    );
    return data;
  }
  static async deleteUser(id: string) {
    const { data } = await AdminApi.http.delete(`/users/user/${id}`);
    return data;
  }
  static async applyAdminPlan(planId: string) {
    const { data } = await AdminApi.http.post(
      `/subscriptions/apply_plan/${planId}`
    );
    return data;
  }
  static async applyPlanByEmail(
    email: string,
    planName: (typeof TARIFFS_NAMES)[keyof typeof TARIFFS_NAMES],
    isAnnual = false
  ) {
    const { data } = await AdminApi.http.post(
      `/subscriptions/apply_plan_by_email?email=${email}&plan_name=${planName}&is_annual=${isAnnual}`
    );
    return data;
  }

  static async getUserHistoryById(userId: string, offset = 0, limit = 50) {
    const { data } = await AdminApi.http.get(
      `/subscriptions/history?user_id=${userId}&offset=${offset}&limit=${limit}`
    );
    return data;
  }
  static async getUserPaymentsHistoryById(
    userId: string,
    offset = 0,
    limit = 50
  ): Promise<{ payments: TPayments; total_payments: number }> {
    const { data } = await AdminApi.http.get(
      `/users/${userId}/payments_history?offset=${offset}&limit=${limit}`
    );
    return data;
  }
}
