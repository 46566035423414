import React, { useState, useEffect } from "react";
import s from "./VideoStore.module.css";
import LArrow from "shared/assets/icons/l-arrow";
import RArrow from "shared/assets/icons/r-arrow";
import Preloader from "shared/ui/Preloader/Preloader";
import generatingLoader from "shared/assets/images/Generation_Background.svg";
import Download from "shared/assets/icons/download";
import Close from "shared/assets/icons/icon-close";

interface Video {
  id: string;
  url: string;
  prompt: string;
  model: string;
  duration: number;
  tokens_usage: number;
  created_at: string;
  user_id: string;
}

interface VideoStoreProps {
  videos: Video[];
  loading_all: boolean;
  loadingVideos: boolean;
}

const VideoStore: React.FC<VideoStoreProps> = ({ videos, loading_all, loadingVideos }) => {
  const [visibleVideos, setVisibleVideos] = useState<number>(10);
  const [selectedVideoIndex, setSelectedVideoIndex] = useState<number | null>(null);
  const [allVideos, setAllVideos] = useState<Video[]>(videos);

  useEffect(() => {
    const updatedVideos = videos.map((video) => ({
      ...video,
      model: video.model.replace("Kling 1.5 Pro Text to Video", "Kling 1.5 Pro"),
      created_at: formatCreationDate(video.created_at),
    }));

    if (loadingVideos) {
      const placeholderVideo: Video = {
        id: "loading-placeholder",
        url: "https://monoai-aws.nyc3.digitaloceanspaces.com/monoai-public/icon.mp4",
        prompt: "Загрузка",
        model: "...",
        duration: 0,
        tokens_usage: 0,
        created_at: "Загрузка",
        user_id: "unknown",
      };
      setAllVideos([placeholderVideo, ...updatedVideos]);
    } else {
      setAllVideos(updatedVideos);
    }
  }, [videos, loadingVideos]);

  const formatCreationDate = (dateString: string): string => {
    const serverDate = new Date(dateString + "Z");
    const offsetHours = 0;
    const utcTimeWithOffset = serverDate.getTime() + offsetHours * 60 * 60 * 1000;
    const userTime = new Date(utcTimeWithOffset);

    const day = String(userTime.getDate()).padStart(2, "0");
    const month = String(userTime.getMonth() + 1).padStart(2, "0");
    const year = String(userTime.getFullYear()).slice(-2);
    const hours = String(userTime.getHours()).padStart(2, "0");
    const minutes = String(userTime.getMinutes()).padStart(2, "0");

    return `${day}.${month}.${year} в ${hours}:${minutes}`;
  };

  const loadMore = () => {
    setVisibleVideos((prev) => prev + 5);
  };

  const openModal = (index: number) => {
    setSelectedVideoIndex(index);
  };

  const closeModal = () => {
    setSelectedVideoIndex(null);
  };

  const goToNextVideo = () => {
    setSelectedVideoIndex((prevIndex) => {
      if (prevIndex === null) return 0;
      return (prevIndex + 1) % allVideos.length;
    });
  };

  const goToPreviousVideo = () => {
    setSelectedVideoIndex((prevIndex) => {
      if (prevIndex === null) return allVideos.length - 1;
      return (prevIndex - 1 + allVideos.length) % allVideos.length;
    });
  };

  // Функция для скачивания видео
  const downloadVideo = () => {
    if (selectedVideoIndex !== null) {
      const videoUrl = allVideos[selectedVideoIndex].url;
      let xhr = new XMLHttpRequest();
      xhr.open('GET', videoUrl, true);
      xhr.responseType = 'blob'; // Указываем, что ответ - это бинарные данные (видео)
      
      xhr.onload = function() {
        let urlCreator = window.URL || window.webkitURL;
        let videoUrlBlob = urlCreator.createObjectURL(this.response); // Создаем URL для видео
        let tag = document.createElement('a');
        tag.href = videoUrlBlob;
        tag.target = '_blank';
        tag.download = 'video.mp4'; // Имя файла при скачивании
        document.body.appendChild(tag);
        tag.click(); // Инициация скачивания
        document.body.removeChild(tag); // Удаляем ссылку после скачивания
      };

      xhr.onerror = err => {
        alert('Не удалось скачать видео');
      };

      xhr.send(); // Отправляем запрос
    }
  };

  return (
    <div className={`${s.imageStore} ${loading_all ? s.loading : ""}`}>
      {loading_all ? (
        <div className={s.preloader}>
          <Preloader />
        </div>
      ) : (
        <>
          {allVideos.slice(0, visibleVideos).map((video, index) => (
            <div key={video.id} className={s.imageWrapper} onClick={() => openModal(index)}>
              <video src={video.url} className={s.imageItem} muted loop />
              <p className={s.imageCaption}>{video.prompt}</p>
              <div className={s.modelTag}>{video.model}</div>
            </div>
          ))}

          {visibleVideos < allVideos.length && !loadingVideos && (
            <button className={s.loadMoreButton} onClick={loadMore}>
              Загрузить ещё
            </button>
          )}
        </>
      )}

      {selectedVideoIndex !== null && (
        <div className={s.modal} onClick={closeModal}>
          <div className={s.modalContent} onClick={(e) => e.stopPropagation()}>
            <button
              className={`${s.navigationButton} ${s.prevButton}`}
              onClick={goToPreviousVideo}
            >
              <LArrow />
            </button>
            <div className={s.modalBody}>
              <video
                src={allVideos[selectedVideoIndex].url}
                controls
                className={s.modalImage}
              />
              <div className={s.imageDetailsContainer}>
                <h3 className={s.imageDetailsTitle}>Детали видео</h3>
                <p className={s.imageDetailsDescription}>
                  {allVideos[selectedVideoIndex].prompt}
                </p>
                <div className={s.detailsColumns}>
                  <div>
                    <p>
                      <strong>Дата</strong> <br />
                      {allVideos[selectedVideoIndex].created_at}
                    </p>
                    <p>
                      <strong>Модель</strong> <br />
                      {allVideos[selectedVideoIndex].model}
                    </p>
                  </div>
                  <div>
                    <p>
                      <strong>Длительность</strong> <br />
                      {allVideos[selectedVideoIndex].duration}
                    </p>
                    <p>
                      <strong>Количество токенов</strong> {allVideos[selectedVideoIndex].tokens_usage}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <button
              className={`${s.navigationButton} ${s.nextButton}`}
              onClick={goToNextVideo}
            >
              <RArrow />
            </button>
            <div className={s.closeContainer}>
              <span className={s.close} onClick={closeModal}>
                <Close />
              </span>
              <button
                className={s.additionalButton}
                onClick={downloadVideo} // Привязка метода для скачивания
              >
                <Download />
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default VideoStore;
