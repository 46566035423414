const IcGradientEye = () => (
<svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15.4186 5.1628H9.15659C6.65155 5.1628 5.39903 5.1628 4.44223 5.65031C3.6006 6.07914 2.91634 6.7634 2.48751 7.60502C2 8.56182 2 9.81434 2 12.3194V24.8434C2 27.3485 2 28.601 2.48751 29.5578C2.91634 30.3994 3.6006 31.0837 4.44223 31.5125C5.39903 32 6.65155 32 9.15659 32H21.6806C24.1857 32 25.4382 32 26.395 31.5125C27.2366 31.0837 27.9209 30.3994 28.3497 29.5578C28.8372 28.601 28.8372 27.3485 28.8372 24.8434V18.5814M10.9457 23.0543H13.4424C14.1717 23.0543 14.5364 23.0543 14.8796 22.9719C15.1838 22.8988 15.4747 22.7783 15.7415 22.6149C16.0424 22.4304 16.3003 22.1726 16.816 21.6569L31.0736 7.39923C32.3088 6.16408 32.3088 4.16151 31.0736 2.92636C29.8385 1.69121 27.8359 1.69121 26.6008 2.92636L12.3431 17.184C11.8274 17.6997 11.5695 17.9576 11.3851 18.2585C11.2216 18.5253 11.1011 18.8162 11.0281 19.1204C10.9457 19.4636 10.9457 19.8283 10.9457 20.5576V23.0543Z" stroke="url(#paint0_linear_6990_219916)" stroke-opacity="0.72" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
<defs>
<linearGradient id="paint0_linear_6990_219916" x1="2" y1="2" x2="32" y2="32" gradientUnits="userSpaceOnUse">
<stop stop-color="#0084FF"/>
<stop offset="0.82" stop-color="#C974FE"/>
<stop offset="1" stop-color="#C974FE"/>
</linearGradient>
</defs>
</svg>

);

export default IcGradientEye;
