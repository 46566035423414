import { createAsyncThunk } from "@reduxjs/toolkit";
import { subscriptionsApi } from "../../api/subscriptionsApi";
import { userActions } from "entities/User/model/slice";

export const getUserSubPlan = createAsyncThunk(
  "user/getUserSubPlan",
  async (_, { rejectWithValue, dispatch }) => {
    // dispatch(userActions.isLoading(true));
    dispatch(userActions.isError(false));

    try {
      const [myPlan, limits] = await Promise.all([
        subscriptionsApi.getMySubscription(),
        subscriptionsApi.getMySubscriptionLimits(),
      ]);
      let maxUserPlan = {};
      if (myPlan.plans.length > 0) {
        maxUserPlan = myPlan?.plans.reduce(function (prev: any, current: any) {
          return prev && prev.tier > current.tier ? prev : current;
        });
      } else {
        maxUserPlan = {
          id: "294c17f3-a448-4962-b284-1c11b298ece8",
          tier: 0.0,
          title: "Бесплатный",
          tokens: 10000.0,
          premium_tokens: 10.0,
          started_at: "",
          ends_at: "",
        };
      }
      return {
        plan: {
          ...myPlan,
          maxUserPlan,
        },
        limits,
      };
    } catch (e) {
      dispatch(userActions.isError(e));
      rejectWithValue(e);
    } finally {
      // dispatch(userActions.isLoading(false));
    }
  }
);
