import { createSlice } from "@reduxjs/toolkit";
import { textDocumentsThunks } from "../services";
import { TDocumentsSchema } from "./types";
import { FamilyModelNames } from "shared/api/types/types";

const initialState: TDocumentsSchema = {
  allDocuments: {
    documents: null,
    folders: [],
  },
  currentDocument: {
    id: "",
    name: "",
  },
  latestVersionDoc: {
    knowledge_object_latest: {},
    body_latest: {},
  },
  selectVersionDoc: {
    knowledge_object_select: {},
    body_select: {},
    version: null,
  },
  gptModels: {},
  currentGptModel: null,
  isLoading: false,
  isError: false,
};

const textDocumentsSlice = createSlice({
  name: "textDocuments",
  initialState,

  reducers: {
    isLoading: (state, { payload }) => {
      state.isLoading = payload;
    },
    isError: (state, { payload }) => {
      state.isError = payload;
    },
    resetCurrentDocument: (state) => {
      state.currentDocument = {
        name: "",
        id: "",
      };
    },
    resetSelectDocument: (state) => {
      state.selectVersionDoc = {
        knowledge_object_select: {},
        body_select: {},
      };
    },

    setFileUrl(state, action) {
      const { originalPath, fileUrl } = action.payload;
      // @ts-ignore
      state.currentDocument.fileUrls[originalPath] = fileUrl;
    },
    // setDocumentAvatar: (state, action) => {
    //   const { id, avatarUrl } = action.payload;
    //   const document = state.allDocuments.find((doc: any) => doc.id === id);
    //   if (document) {
    //     document.avatar = avatarUrl;
    //   }
    // },
    // setCurrentAvatar: (state, action) => {
    //   const { avatar } = action.payload;
    //   state.currentDocument.avatar = avatar;
    // },

    resetLatestVersion: (state) => {
      state.latestVersionDoc = {
        knowledge_object_latest: {},
        body_latest: {},
      };
    },
    setCurrentGptModel: (
      state,
      { payload }: { payload: { modelName: FamilyModelNames } }
    ) => {
      state.currentGptModel = payload.modelName;
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(
        textDocumentsThunks.getAllTextDocuments.fulfilled,
        (state, { payload }) => {
          state.allDocuments = payload?.allTextDocuments || [];
        }
      )
      .addCase(
        textDocumentsThunks.getGptModels.fulfilled,
        (state, { payload }) => {
          if (payload) {
            state.gptModels = payload.gptModels;
            state.currentGptModel = Object.keys(
              payload.gptModels.Openai
            )[0] as any; // FamilyModelNames
          }
        }
      )
      .addCase(
        textDocumentsThunks.createNewVersion.fulfilled,
        (state, { payload }) => {
          state.currentDocument.older_versions?.push(payload.version);
          //TODO обновить версию mongo_id
          // console.log(payload.newBlocks);
          if (state.currentDocument.body) {
            state.currentDocument.body.template = payload?.newBlocks;
          }
        }
      )
      .addCase(
        textDocumentsThunks.getDocumentBody.fulfilled,
        (state, { payload }) => {
          state.currentDocument.body = payload?.body;

          // state.latestVersionDoc.body_latest = action.payload.body;
        }
      )

      .addCase(
        textDocumentsThunks.createNewFolder.fulfilled,
        (state, action) => {
          state.allDocuments.folders = [
            action.payload.folder,
            ...(state.allDocuments.folders || []),
          ];
        }
      )

      .addCase(
        textDocumentsThunks.getCurrentTextDocument.fulfilled,
        (state, { payload }) => {
          state.currentDocument = payload.currentDoc;
        }
      )
      .addCase(
        textDocumentsThunks.updateDocument.fulfilled,
        (state, { payload }) => {
          if (payload?.updateDocument.folder_id) {
            const folder = state.allDocuments.folders?.find(
              (doc: any) => doc.id === payload.updateDocument.folder_id
            );
            if (folder) {
              const doc = folder.documents.find(
                (doc: any) => doc.id === payload.updateDocument.id
              );
              if (doc) {
                doc.name = payload.updateDocument.name;
              }
            }
          } else {
            const index = state.allDocuments.documents?.findIndex(
              (document) => document.id === payload?.updateDocument.id
            );
            if (
              index !== -1 &&
              state.allDocuments.documents &&
              index !== undefined
            ) {
              state.allDocuments.documents[index].name =
                payload?.updateDocument.name;

              if (state.currentDocument.id === payload?.updateDocument.id) {
                state.currentDocument.name = payload?.updateDocument.name;
              }
            }
          }
        }
      )
      .addCase(
        textDocumentsThunks.updateFolder.fulfilled,
        (state, { payload }) => {
          const folderId = payload?.updatedFolder.id;
          if (folderId) {
            const folder = state.allDocuments.folders?.find(
              (folder: any) => folder.id === folderId
            );
            if (folder) {
              folder.name = payload?.updatedFolder.name;
            }
          }
        }
      )
      .addCase(
        textDocumentsThunks.createNewDocument.fulfilled,
        (state, { payload }) => {
          state.allDocuments.documents = [
            payload.document,
            ...(state.allDocuments.documents || []),
          ];
          state.currentDocument = payload.document;
        }
      )
      .addCase(
        textDocumentsThunks.duplicateDocument.fulfilled,
        (state, { payload }) => {
          if (payload?.newDoc.folder_id) {
            const folder = state.allDocuments.folders?.find(
              (doc: any) => doc.id === payload.newDoc?.folder_id
            );
            if (folder) {
              folder.documents = [payload.newDoc, ...folder.documents];
              folder.documents.sort((a, b) => {
                if (a.created_at && b.created_at) {
                  return a.created_at.localeCompare(b.created_at);
                }
                return -1;
              });
            }
          } else {
            state.allDocuments.documents = [
              ...(state.allDocuments.documents || []),
              payload.newDoc,
            ];
          }
        }
      )

      .addCase(
        textDocumentsThunks.deleteDocument.fulfilled,
        (state, { payload }) => {
          const filterCallback = (doc: any) =>
            doc.id !== payload?.deletedDoc.id;

          if (payload?.deletedDoc.folder_id) {
            const folder = state.allDocuments?.folders?.find(
              (folder) => folder.id === payload.deletedDoc.folder_id
            );

            if (folder) {
              folder.documents = folder.documents.filter(filterCallback);
            }
          } else if (state.allDocuments.documents) {
            state.allDocuments.documents =
              state.allDocuments.documents.filter(filterCallback);
          }

          if (state.currentDocument.id === payload?.deletedDoc.id) {
            state.currentDocument = { id: "" }; //state.allDocuments.documents[0];
          }
        }
      )

      .addCase(
        textDocumentsThunks.addDocumentToFolder.fulfilled,
        (state, { payload }) => {
          const addedDocId = payload.documentId;
          const folderID = payload.folder_id;
          const doc = state.allDocuments.documents?.find(
            (document) => document.id === addedDocId
          );
          if (doc && state.allDocuments.documents) {
            doc.folder_id = folderID;

            const folder = state.allDocuments.folders?.find(
              (fold) => fold.id === folderID
            );
            if (folder) {
              folder.documents = [doc, ...(folder?.documents || [])];
            }

            state.allDocuments.documents = state.allDocuments.documents.filter(
              (doc) => doc.id !== addedDocId
            );
          }
        }
      )

      .addCase(
        textDocumentsThunks.deleteDocFromFolder.fulfilled,
        (state, { payload }) => {
          const folderIndex = state.allDocuments.folders?.findIndex(
            (fold) => fold.id === payload.folder_id
          );
          if (folderIndex !== undefined && folderIndex !== -1) {
            const folder = state.allDocuments.folders?.[folderIndex];

            const index = folder?.documents?.findIndex(
              (doc: any) => doc.id === payload.docId
            );

            if (index !== -1 && index !== undefined) {
              const doc = folder?.documents[index];

              doc!.folder_id = "";

              folder!.documents = folder!.documents.filter(
                (doc: any) => doc.id !== payload.docId
              );
              state.allDocuments.documents = [
                doc!,
                ...(state.allDocuments.documents || []),
              ];
            }
          }
        }
      )
      .addCase(
        textDocumentsThunks.deleteFolder.fulfilled,
        (state, { payload }) => {
          const deletedFolder = state.allDocuments.folders?.find(
            (folder) => folder.id === payload?.deletedFolderId
          );

          if (
            deletedFolder?.documents?.find(
              (doc) => doc.id === state?.currentDocument.id
            )
          ) {
            state.currentDocument = {
              id: "",
              name: "",
            };
          }
          state.allDocuments.folders = state.allDocuments.folders?.filter(
            (folder) => folder.id !== payload?.deletedFolderId
          );
        }
      );
  },
});

export const textDocumentsActions = textDocumentsSlice.actions;

export const textDocumentsReducer = textDocumentsSlice.reducer;
