import { createSlice } from "@reduxjs/toolkit";
import { subscriptionsThunks } from "../services/thunks";
import { TUserSubPlanSchema } from "../types/TUserSubPlanSchema";

const initialState: TUserSubPlanSchema = {
  userPlan: null,
  isLoading: false,
  isError: false,
  isTokensLimitShown: false,
  limits: {
    max_ai_chat_context: 0,
    max_ai_chat_file_size_mb: 0,
    max_ai_dubbing_input_length_min: 0,
    max_ai_voices_quantity: 0,
    max_audio_file_size_mb: 0,
    max_documents_quantity: 0,
  },
};

const userSubPlanSlice = createSlice({
  name: "userSubPlan",
  initialState,
  reducers: {
    isLoading(state, { payload }) {
      state.isLoading = payload;
    },
    isError(state, { payload }) {
      state.isError = payload;
    },
    changeTokensCount(state, { payload }) {
      if (state.userPlan && payload.used_tokens) {
        state.userPlan.tokens -= payload.used_tokens;
      }
    },
    changePremiumTokensCount(state, { payload }) {
      if (state.userPlan && payload.used_tokens) {
        state.userPlan.premium_tokens -= payload.used_tokens;
      }
    },
    setIsTokensLimitShown(state, { payload }) {
      state.isTokensLimitShown = payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(
      subscriptionsThunks.getUserSubPlan.fulfilled,
      (state, { payload }) => {
        if (payload?.plan) {
          state.userPlan = payload.plan;
          state.limits = payload.limits;
        }
      }
    );
  },
});

export const userSubPlanReducer = userSubPlanSlice.reducer;
export const userSubPlanActions = userSubPlanSlice.actions;
