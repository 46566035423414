import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios, { AxiosError } from "axios";
import { RoutePath } from "shared/config/routeConfig/routeConfig";
import Preloader from "shared/ui/Preloader/Preloader";
import AuthApi from "features/Auth/services/AuthApi";
import { LS_ACCESS_TOKEN } from "shared/api/constants/localStorage";

const AuthCallbackPage = () => {
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    // fetch("https://dev.monoai.ru/yandex-auth/")
    //   .then((response) => response.json())
    //   .then((data) => {
    //     console.log(data.headers);
    //   });
    const handleAuthCallback = async () => {
      const params = new URLSearchParams(window.location.search);

      //   const hash = new URLSearchParams(window.location.hash);

      const data = {};

      //   const searchParams = window.location.search;
      const hashParams = window.location.hash;
      console.log(hashParams.length);
      if (params.get("access_token")) {
        // @ts-ignore
        data.access_token = params.get("access_token");
        // @ts-ignore
        data.expires_in = params.get("expires_in");
        // @ts-ignore
        data.token_type = params.get("token_type");
        // console.log(data);
      } else if (hashParams.length) {
        const values = hashParams.slice(1).split("&");

        values.forEach((param) => {
          const [key, value] = param.split("=");
          // @ts-ignore
          data[key] = value;
        });

        // console.log(data);
      } else {
        setError("Authorization token is missing.");
        setIsLoading(false);
        return;
      }
      //   //   console.log(searchParams, hashParams);
      //   // Удаляем "#" и разбиваем строку по символу "&"
      //   const de = hashParams.slice(1).split("&");

      //   // Создаем объект для хранения ключей и значений
      //   const yandexData = {};

      //   de.forEach((param) => {
      //     const [key, value] = param.split("=");
      //     // @ts-ignore
      //     yandexData[key] = value;
      //   });

      // Вывод результата
      //   console.log(yandexData);
      //   const access_token = params.get("access_token");
      //   const expires_in = params.get("expires_in");
      //   const token_type = params.get("token_type");
      //   console.log(access_token, expires_in);

      // @ts-ignore
      //   if (!yandexData.access_token || !yandexData.expires_in) {
      //     setError("Authorization token is missing.");
      //     setIsLoading(false);
      //     return;
      //   }
      try {
        const registerResponse = await AuthApi.yandexOauth(data);
        // if (registerResponse.status === 200) {
        setIsLoading(false);
        localStorage.setItem(LS_ACCESS_TOKEN, registerResponse.access_token);
        navigate(RoutePath.MAIN);
        // } else {
        //   setError("Failed to register or login.");
        //   console.error("Failed to register or login.");
        // }
      } catch (error: unknown) {
        if (axios.isAxiosError(error)) {
          const axiosError = error as AxiosError<{ message: string }>;
          setError(
            "Axios error: " +
              (axiosError.response?.data?.message || axiosError.message)
          );
        } else if (error instanceof Error) {
          setError("Unknown error: " + error.message);
        } else {
          setError("An unexpected error occurred.");
        }
      } finally {
        setIsLoading(false);
      }
    };
    handleAuthCallback().catch((err: Error) => {
      console.error("Error handling the auth callback: ", err);
      setError("An error occurred during the authentication process.");
      setIsLoading(false);
    });
  }, []);

  if (isLoading) {
    return <Preloader />;
  }

  return <div>{error && <div>Error: {error}</div>}</div>;
};

export default AuthCallbackPage;
