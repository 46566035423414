import { createSlice } from "@reduxjs/toolkit";
import { TUsersSchema } from "../types/TUsersSchema";
import { usersThunks } from "../services/thunks";

const initialState: TUsersSchema = {
  users: null,
  totalUsers: null,
  isLoading: false,
  isError: false,
};

const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    isLoading(state, { payload }) {
      state.isLoading = payload;
    },
    isError(state, { payload }) {
      state.isError = payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(usersThunks.getUsersList.fulfilled, (state, { payload }) => {
        if (payload) {
          state.users = payload.users;
          if (!state.totalUsers) state.totalUsers = payload.total_users;
        }
      })
      .addCase(usersThunks.deleteUser.fulfilled, (state, { payload }) => {
        if (payload && state.users) {
          state.users = state.users.filter((user) => user.id != payload.id);
        }
      });
  },
});

export const usersReducer = usersSlice.reducer;
export const usersActions = usersSlice.actions;
