import { createAsyncThunk } from "@reduxjs/toolkit";

import { usersActions } from "../../slice";
import { AdminApi } from "entities/Admin";

export const deleteUser = createAsyncThunk(
  "users/deleteUser",
  async ({ id }: { id: string }, { dispatch }) => {
    dispatch(usersActions.isError(null));
    try {
      await AdminApi.deleteUser(id);
      return { id };
    } catch (e) {
      dispatch(usersActions.isError(e));
    }
  }
);
