import { FC, memo } from "react";
import s from "./PageTitle.module.css";
import UserBalance, { BalancePosition } from "../UserBalance/UserBalance";

export type TPageTitle = {
  title: string;
  button?: JSX.Element;
  description?: string;
  titleClass?: string
};
const PageTitle: FC<TPageTitle> = memo(({ title, button, description, titleClass }) => {
  return (
    <div className={s.container}>
      <div className="flex flex-col gap-3">
        <h1 className={`${s.h1} ${titleClass || ''}`}>{title}</h1>
        {description && <p className={s.description}>{description}</p>}
      </div>
      {button ?? (
        <UserBalance position={BalancePosition.ROW} className={s.balance} />
      )}
    </div>
  );
});

export default PageTitle;
